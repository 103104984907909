import { TipListModel } from "@/models/TipsModel";

/*
 * @Author: xyz-d 1263237040@qq.com
 * @Date: 2022-11-25 14:27:31
 * @LastEditors: xyz-d 1263237040@qq.com
 * @LastEditTime: 2023-10-05 15:37:56
 * @FilePath: \apdclient\src\store\staticValue.ts
 * @Description: 
 */
export const ACCESS_TOKEN = 'ACCESS-TOKEN'; // 用户token
export const CURRENT_USER = 'CURRENT-USER'; // 当前用户信息
export const IS_LOCKSCREEN = 'IS-LOCKSCREEN'; // 是否锁屏
export const TABS_ROUTES = 'TABS-ROUTES'; // 标签页
export const RESOLUTION = {
  WIDTH_A4: 11880,
  HEIGHT_A4: 8400,
  WIDTH_A4_CENTER: 10692,
  HEIGHT_A4_CENTER: 6090,
  WIDTH_2K: 2048,
  HEIGHT_2K: 1080,
  WIDTH_4K: 3840,
  HEIGHT_4K: 2160,
  WIDTH_8K: 7680,
  HEIGHT_8K: 4320,
  WIDTH_16K: 15360,
  HEIGHT_16K: 8640,
}

export enum ResolutionType {
  ZoomOut,
  _A4,
  _2K,
  _4K,
  _8K,
  _16K,
  Handbook_8K,
}

export enum ExportCSVSettingType {
  Default,
  UE_Move,
  UE_Static,
  UE_DivideXY
}


export enum PageEnum {
  // 登录
  BASE_LOGIN = '/login',
  BASE_LOGIN_NAME = 'Login',
  //重定向
  REDIRECT = '/redirect',
  REDIRECT_NAME = 'Redirect',
  // 注册
  REGISTER = '/register',
  REGISTER_NAME = 'Register',
  // 忘记密码
  CHANGE_PASSWORD = '/change_password',
  CHANGE_PASSWORD_NAME = 'ChangePassword',
  // 首页
  BASE_HOME = '/home',
  BASE_HOME_NAME = 'Home',
  //首页跳转默认路由
  BASE_HOME_REDIRECT = '/',
  // 错误
  ERROR_PAGE_NAME = 'ErrorPage',
}

export enum ResultEnum {
  SUCCESS = 200,
  ERROR401 = 401,
  ERROR402 = 402,
  ERROR403 = 403,
  ERROR404 = 404,
  TIMEOUT = 10042,
  TYPE = 'success',
}

// 道具图形选项
export enum GraphicsType {
  Torus,
  Circle,
  Ellipse,
  Triangle,
  Rectangle,
  Parallelogram,
  Star,
  Pentagon,
  Hexagon,
  Ngon,
  Picture
}

// 鼠标事件类型
export enum EventMode {
  Normal,
  MapAgent,
  AddAgent,
  DrawLine,
  DrawGraphic,
  ShowModal,
}

// Frame 活跃状态
export enum ActiveType {
  Active,
  Inactive,
  // MapModeActive
}

// 输入图片的类型 场地背景图/图片道具
export enum InputPicType {
  Background,
  Prop
}

// 保存的方式 另存为/覆盖
export enum SaveMode {
  NewFile,
  Overlay
}

// 生成点位的方式
export enum GenerateAgentType {
  DuplicateDelete,
  RandomDelete,
  RandomMove,
  Rotate,
  Flip,
  Scale,
  Line,
  Fill,
  Outline,
  Skeleton
}

// 网格文字的类型 字母/数字/数字倒序
export enum GridOrderType {
  Letter,
  Number,
  NumberReverse
}

// 匹配功能列表
export enum MapFuncType {
  AgentMap,
  MakeMarker,
  ClearIndex,
  ClearMidPoint,
  DivideMidPoint
}

// 点位匹配方式
export enum AgentMapType {
  Normal,
  ByGroup,
  GenerateGroupByKM,
}

// 输出 csv 的选项：单帧 多帧 映射关系
export enum OutputCSVType {
  SinglePage,
  MultiPage,
  MappingRelationInSinglePage,
  UE_DivideXY
}

// 设置组内顺序的方式
export enum SetOrderType {
  Auto,
  Single,
}

// 点位翻转方式
export enum FlipType {
  FlipTop2Bottom,
  FlipLeft2Right
}

export const Tips: Array<TipListModel> = [
  {
    listTitle: '工作模式',
    item: [
      {
        title: '工作模式在画布的右上角显示',
        context: '- 进入特殊工作模式时会显示"退出"按钮'
      },
      {
        title: '查看模式',
        context: '- 系统的基本模式\n- 没有"退出"按键'
      },
      {
        title: '输入模式',
        context: '- 在部分用户输入场景中会自动进入此模式\n- 此模式下禁用画布快捷键和点击操作'
      },
      {
        title: '添加Agent模式',
        context: "- 所有鼠标点击事件都会添加 Agent \n- 添加方式由所选择的方法而定 "
      },
      {
        title: '匹配模式',
        context: "- 用于查看、建立前后帧之间的点位映射\n- 会将下一帧以灰色的、不可交互的形式呈现在当前画布中\n" +
          "- 此模式下拖动 Agent 仅为了方便进行映射调整, 不会造成其原位置的改变"
      },
      {
        title: '画线模式',
        context: "- 此模式下鼠标点击会添加一个线段的节点\n- 按\"L\"键可进入、退出此模式\
        \n- 按\"Shift\"键可垂直、水平画线\n- 退出此模式时即完成画线 \n- 新增连线模式：\
        \n  1：选中点位后按“L”即为连线选中点的功能；按组别从小到大、按组内顺序从小到大依次连线\n  2：不选中点位按“L”即为自由连线功能"
      },
      {
        title: '添加道具模式',
        context: "- 此模式下鼠标点击会添加一个道具\n- 道具由用户设定的配置内容而定\n- 退出此模式时即完成道具添加"
      },
    ]
  },
  {
    listTitle: '画布操作',
    item: [
      {
        title: '视角拖动',
        context: '- 按住鼠标右键并拖动'
      },
      {
        title: '视角缩放',
        context: "- 在画布的中滚动鼠标滚轮\n" +
          "- 向前滚动: 视角放大\n" +
          "- 向后滚动: 视角缩小"
      },
    ]
  },
  {
    listTitle: '点位操作',
    item: [
      {
        title: '通过道具生成点位',
        context:
          `支持透明、黑色背景的图片或者图形道具生成点位
1. 插入一个图片道具
2. 选中该道具
3. 选择 "其他点位更改方法"
4. 选择 "填充式生成"、"沿轮廓生成"、"提取骨架生成" 并配置好参数
5. 点击 "确定" 生成点位`
      },
    ]
  },
  {
    listTitle: '快捷键',
    item: [
      {
        title: '全选',
        context: `- 选中部分点位后, 按 "A" 键会将其包含的组别的所有点位选中`
      },
      {
        title: '复制点位',
        context: `- "Ctrl + C" 复制选中的点位`
      },
      {
        title: '粘贴点位',
        context: `- "Ctrl + V" 粘贴复制的点位到当前帧`
      },
      {
        title: '多选',
        context: `- 对 Agent 进行选择时按住 "Ctrl" 键可以进行多选`
      },
      {
        title: '反选',
        context: `- 对 Agent 进行选择时按住 "Ctrl + Alt" 可以进行反选`
      },
      {
        title: '删除映射结果',
        context: `- 在匹配模式下, 按 "C" 键会删除选中 Agent 的映射结果`
      },
      {
        title: '删除运动过程',
        context: `- 在查看模式下, 按 "C" 键会清空选中 Agent 的运动过程`
      },
      {
        title: '删除画布元素',
        context: `- 按 "Del" 键删除选中的画布元素(Agent、线条、道具)`
      },
      {
        title: '在末尾添加关键帧',
        context: `- 按 "+" 键在项目的末尾添加一个新关键帧`
      },
      {
        title: '插入关键帧',
        context: `- 按 "Insert" 键当前帧前插入一个新关键帧`
      },
      {
        title: '向前翻页',
        context: `- 按 "PageUp" 键向前翻页`
      },
      {
        title: '向后翻页',
        context: `- 按 "PageDown" 键向后翻页`
      },
      {
        title: '分组',
        context: `- 按 "G" 键对选中的Agent进行分组, 或更改组别对应的颜色`
      },
      {
        title: '画线',
        context: `- 按 "L" 键进入、退出画线模式
  1：选中点位后按“L”即为连线选中点的功能；按组别从小到大、按组内顺序从小到大依次连线
  2：不选中点位按“L”即为自由连线功能`
      },
      {
        title: '生成映射',
        context: `- 选中未匹配过的 Agent, 按 "Q" 键对其执行 KM 算法生成映射结果`
      },
      {
        title: '退出当前模式',
        context: `- 按 "Esc" 键退出当前的特殊模式`
      },
    ]
  },
  {
    listTitle: '系统功能新增日志',
    item: [
      {
        title: '设置组内顺序',
        context: `- 操作：设置组内顺序/按键“O” → 输入组内顺序值/自动排序的起始值 → 确定
- 说明：两种设置组内顺序的方法：
  1. 根据选中的起始点和输入的起始值自动依次编号
  2. 根据输入设置选中点位的组内顺序值
- 时间：2023.08.03`
      },
      {
        title: '根据图形道具和间距填充生成点位',
        context: `- 操作：选中图形道具 → 其他点位更改方法 → 填充式生成
- 说明：新增根据图形道具和间距填充生成点位
- 时间：2023.07.31`
      },
      {
        title: '显示线条长度',
        context: `- 操作：选中线条
- 说明：选中线条即可显示该线条的长度
- 时间：2023.07.29`
      },
      {
        title: '连接选中的点',
        context: `- 操作：选中需要连线的点位 → 点击“辅助”-“画线”/按“L”键
- 说明：连线的逻辑为按组别从小到大再按组内顺序从小到大依次连线
  改变了原有的自由画线逻辑：
  1：选中点位后按“L”即为连线选中点的功能；按组别从小到大、按组内顺序从小到大依次连线。
  2：不选中点位按“L”即为自由连线功能
- 时间：2023.07.29`
      },
      {
        title: '删除重复点位',
        context: `- 操作：其他点位更改方法 → 删除重复点位
- 说明：删除一定范围内重叠的点位，可与吸附功能相结合使用
- 时间：2023.07.28`
      },
      {
        title: '仅导入设置',
        context: `- 操作：打开 → 仅导入设置(左下角)
- 说明：为当前项目导入目标项目的设置数据（不变更项目，仅导入设置）
- 时间：2023.07.27`
      },
      {
        title: '项目设置跟随项目保存',
        context: `- 操作：自动保存
- 说明：项目设置数据会跟随项目自动保存，下次打开时自动载入设置
- 时间：2023.07.27`
      },
      {
        title: '输出CSV配置预设',
        context: `- 操作：输出点位数据 → 输出配置预设
- 说明：增加了两种输出格式的配置预设：“默认输出配置”、“为UE输出动帧”、“为UE输出静帧”
- 时间：2023.07.24`
      },
      {
        title: '匹配后自动等分运动过程',
        context: `- 操作：进行匹配后自动设置
- 说明：匹配后自动根据匹配结果将运动过程等分为60帧, 如需要修改再次执行N等分即可
- 时间：2023.07.23`
      },
      {
        title: '输出坐标轴数据',
        context: `- 操作：输出点位数据 → 输出多帧映射数据
- 说明：输出的点位坐标为其最近的网格点，请先配置好网格点，可根据映射输出多帧或单帧数据
- 时间：2023.07.16`
      },
      {
        title: '吸附功能',
        context: `- 操作：设置 → 画布设置 → 吸附
- 说明：吸附的间隔在下方的“间隔长度”、“间隔宽度”中设置，仅在拖动点位时吸附生效
- 时间：2023.07.16`
      },
    ]
  },
]