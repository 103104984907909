
import { defineComponent } from 'vue'
import { useMessage } from 'naive-ui'

export default defineComponent({
  name: 'globalView',
  setup() {
    window.$message = useMessage()
  }
})
