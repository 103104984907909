/*
 * @Author: xyz-d 1263237040@qq.com
 * @Date: 2022-11-25 14:33:10
 * @LastEditors: xyz-d 82856169+xyz-d@users.noreply.github.com
 * @LastEditTime: 2023-04-24 19:04:23
 * @FilePath: \apdclient\src\services\UserService.ts
 * @Description: 
 */
import axios from "axios"
import { UserInfo, LoginForm, SendMailForm, RegisterForm } from '@/models/UserModel'
import BasicResponseModel from "@/models/ResponseModel"
import { useUserStore } from "@/store/userStore"

export default class UserService {
  public static async login(data: LoginForm): Promise<BasicResponseModel<UserInfo>> {
    return (await axios.post('api/login', { data })).data
  }

  public static async verifyToken(): Promise<BasicResponseModel<{ status: boolean, name: string }>> {
    return (await axios.get('api/verify', { headers: { 'Authorization': `${useUserStore().token}` } })).data
  }

  public static async registerEmail(data: SendMailForm): Promise<BasicResponseModel<{ status: boolean, name: string }>> {
    return (await axios.post('api/register_email', { data })).data
  }

  public static async changePasswordEmail(data: SendMailForm): Promise<BasicResponseModel<{ status: boolean, name: string }>> {
    return (await axios.post('api/change_password_email', { data })).data
  }

  public static async register(data: RegisterForm): Promise<BasicResponseModel<{ status: boolean, name: string }>> {
    return (await axios.post('api/register', { data })).data
  }

  public static async changePassword(data: RegisterForm): Promise<BasicResponseModel<{ status: boolean, name: string }>> {
    return (await axios.post('api/change_password', { data })).data
  }
}