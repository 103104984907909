/*
 * @Author: xyz-d 1263237040@qq.com
 * @Date: 2022-11-25 13:06:06
 * @LastEditors: xyz-d 82856169+xyz-d@users.noreply.github.com
 * @LastEditTime: 2023-04-24 18:46:36
 * @FilePath: \apdclient\src\router\index.ts
 * @Description: 
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import { storage } from '@/utils/Storage'
import { ACCESS_TOKEN, PageEnum } from '@/store/staticValue'
import { useUserStore } from '@/store/userStore'
import { LoginForm } from '@/models/UserModel'
import encryptPassword from '@/utils/RSA'
import RegisterView from '@/views/RegisterView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: PageEnum.BASE_LOGIN,
    name: PageEnum.BASE_LOGIN_NAME,
    component: LoginView
  },
  {
    path: PageEnum.REGISTER,
    name: PageEnum.REGISTER_NAME,
    component: RegisterView
  },
  {
    path: PageEnum.CHANGE_PASSWORD,
    name: PageEnum.CHANGE_PASSWORD_NAME,
    component: ChangePasswordView
  },
  {
    path: PageEnum.BASE_HOME_REDIRECT,
    alias: PageEnum.BASE_HOME,
    name: PageEnum.BASE_HOME_NAME,
    component: () => import('@/views/HomeView.vue'),

    //* 登录验证
    beforeEnter: async (to, from) => {
      try {
        if (await useUserStore().isAuthenticated()) {
          return true
        } else {

          // 默认的登录权限
          // const params: FormState = {
          //   account: 'admin',
          //   password: '123456'
          // }
          // let psd = encryptPassword(params.password)
          // params.password = psd as string
          // await useUserStore().login(params)
          // return true

          // 正常的登录验证
          return { path: PageEnum.BASE_LOGIN }
        }
      } catch (e) {
        // return true

        // 正常的登录验证 
        return { path: PageEnum.BASE_LOGIN }
      }
    },
    //*/
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
