
import { NLoadingBarProvider, NConfigProvider, NDialogProvider, NNotificationProvider, NMessageProvider } from 'naive-ui'
import GlobalView from '@/views/GlobalView.vue'
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    // NLoadingBarProvider,
    NConfigProvider, NDialogProvider, NNotificationProvider, NMessageProvider, GlobalView
  }
})
