/*
 * @Author: xyz-d 1263237040@qq.com
 * @Date: 2022-11-25 20:41:16
 * @LastEditors: xyz-d 1263237040@qq.com
 * @LastEditTime: 2022-11-26 16:23:55
 * @FilePath: \apdclient\src\utils\RSA.ts
 * @Description: 
 */
import JSEncrypt from 'jsencrypt'

let pubKey = '-----BEGIN RSA PUBLIC KEY-----MEgCQQCp7QXkXJCpqFwUpFRzDaCTJwiWqHuaayTN/b93y396QYJAu+I+NQJZYkaOOkqn2VyuMcq+mDyP+TW3kv7B8Mn5AgMBAAE=-----END RSA PUBLIC KEY-----'

export default function encryptPassword(str: string) {
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(pubKey);
  let encrypted = encrypt.encrypt(str);
  // console.log('加密后数据:%o');
  // console.log(encrypted);
  return encrypted
}

