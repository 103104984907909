/*
 * @Author: dlk 1263237040@qq.com
 * @Date: 2022-11-11 19:40:57
 * @LastEditors: xyz-d 1263237040@qq.com
 * @LastEditTime: 2023-07-16 18:05:53
 * @FilePath: \apdclient\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import naive from 'naive-ui'

async function bootstrap() {
  const app = createApp(App)
  app.use(router).use(VueAxios, axios).use(createPinia()).use(naive)
  app.provide('axios', app.config.globalProperties.axios)
  await router.isReady()
  app.mount('#app', true)
}

void bootstrap();
