import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_view = _resolveComponent("global-view")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_n_dialog_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_notification_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_message_provider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_global_view)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}