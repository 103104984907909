/*
 * @Author: xyz-d 1263237040@qq.com
 * @Date: 2022-11-25 14:15:39
 * @LastEditors: xyz-d 82856169+xyz-d@users.noreply.github.com
 * @LastEditTime: 2023-04-22 16:12:12
 * @FilePath: \apdclient\src\store\userStore.ts
 * @Description: 
 */
import { defineStore } from 'pinia'
import { storage } from '@/utils/Storage'
import { ACCESS_TOKEN, CURRENT_USER, ResultEnum } from '@/store/staticValue'
import { UserInfo, LoginForm } from '@/models/UserModel'
import UserService from '@/services/UserService'
import axios from 'axios'

export const useUserStore = defineStore('userStore', {
  state: () => {
    return {
      token: storage.get(ACCESS_TOKEN, ''),
      userName: '',
      autoLogin: true,
      _isAuthenticated: false
    }
  },
  actions: {
    async login(userInfo: LoginForm) {
      try {
        const response = await UserService.login(userInfo)
        const { result, code } = response

        if (code == ResultEnum.SUCCESS) {
          if (this.autoLogin) {
            const ex = 7 * 24 * 60 * 60
            storage.set(ACCESS_TOKEN, result.token, ex)
            storage.set(CURRENT_USER, result.userName, ex)
          }
          this.setData(result)
        }
        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    setData(result: UserInfo) {
      this.token = result.token
      this.userName = result.userName
      this._isAuthenticated = true
      axios.defaults.headers.common['Authorization'] = `${storage.get(ACCESS_TOKEN, '')}`;
    },
    logout() {
      this._isAuthenticated = false
      this.token = ''
      this.userName = ''
      storage.remove(ACCESS_TOKEN)
      storage.remove(CURRENT_USER)
    },
    async isAuthenticated(): Promise<boolean> {
      if (storage.get(ACCESS_TOKEN, '') != null) {
        const { result } = await UserService.verifyToken()
        this._isAuthenticated = result.status
        this.userName = result.name
      } else {
        this._isAuthenticated = false
      }
      return this._isAuthenticated
    }
  }
})